.app {
  background: url('./assets/dark-background.png') no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  overflow: hidden;
}

.control-element-top {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
}

.description-wrapper {
  text-align: center;
}

