.dark-header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.dark-header {
  height: 110px;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 130px;
  font-weight: 400;
  font-family: 'Vollkorn-Regular';
  color: var(--color-white);
}

.dark-description {
  font-size: 22px;
  font-weight: 500;
  font-family: 'MonserratAlternates-Medium';
  color: var(--color-orange);
}

.image {
  position: relative;
  bottom: -1px;
  padding: 0 2px;
}

@media only screen and (max-width: 360px) {
  .dark-header {
    height: auto;
    font-size: 3rem;
  }
}

@media only screen and (max-width: 560px) {
  .dark-header {
    height: auto;
    font-size: 4.5rem;
  }
}
